<template>
  <div class="not_found">
    <inline-svg :src="require('../assets/logo-t.svg')"></inline-svg>

    <h1>
      404 <br />
      page not found
    </h1>
  </div>
</template>
<style lang="sass" scoped>
.not_found
  width: 100%
  margin-top: 15rem
  margin-left: 2rem
  margin-right: 2rem

  h1
    margin-top: 4rem
    text-align: center
</style>
